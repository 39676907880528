<template lang="">
  <section
    v-if="showSection"
    class="investment-performance container-fluid py-5"
  >
    <div class="row row-container">
      <div class="col-12 col-md-6 col-xl-5 column-container">
        <div class="content-header ms-lg-5">
          <SubtitleComponent
            customClasses="text-white subtitle pt-5 ms-lg-5 mb-3 wow slideInLeft"
            data-wow-duration="3s"
            text="¿Cómo funciona la inversión?"
          >
          </SubtitleComponent>
        </div>
        <div class="content ms-lg-5 pb-5 pb-md-0">
          <div
            class="line ms-lg-5 mb-5 wow slideInLeft"
            data-wow-duration="3s"
          ></div>
          <p class="description ms-lg-5 wow slideInLeft" data-wow-duration="3s">
            La inversión es por un plazo de 3 años, en los cuales, manejaremos
            tu capital en el fondo ofreciéndote un retorno preferencial del 8%
            anual. El primer retorno del 8% se pagará al final del primer año de
            inversión. El retorno del 8% del segundo año se pagará de forma
            semestral, y el retorno del 8% del tercer año se pagará
            trimestralmente. <br />
            <br />
            Además del retorno anual del 8%, podrá haber una utilidad adicional
            ya sea cuando se venda la propiedad al final del periodo o bien si
            se consiguen rentas por encima del 8%, la cual será pagada al final
            del periodo. Esa utilidad se repartiría 25% para el inversionista.
          </p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-7 column-container my-md-auto">
        <img :src="bgImage" alt="" class="img-fluid" />
      </div>
    </div>
  </section>
</template>

<script>
import SubtitleComponent from "@/components/SubtitleComponent.vue";
import axios from "axios";

export default {
  name: "InvestmentPerformance",
  components: {
    SubtitleComponent,
  },
  data() {
    return {
      bgImage: require("@/assets/img/chart.png"),
      country: "",
      showSection: true,
    };
  },
  mounted() {
    this.checkLanguage();
  },
  methods: {
    checkLanguage() {
      axios.get("https://ipinfo.io?token=fc98dd7e9cf837").then((res) => {
        console.log(res.data.country);
        this.country = res.data.country;
        if (this.country === "US") {
          this.showSection = false;
        }
      });
    },
  },
};
</script>
<style lang=""></style>
