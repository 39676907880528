<template>
  <section
    class="start-investing section"
    :style="{ backgroundImage: 'url(' + bgImage + ')' }"
  >
    <div class="content">
      <div class="content-items text-center">
        <TitleComponent
          customClasses="text-white title-one text-center mb-5 wow fadeInDown"
          text="Empieza a invertir"
        >
        </TitleComponent>
        <ButtonComponent
          customClasses="outline-custom-btn btn rounded-0 px-5 py-2"
          text="AHORA"
          :method="this.showSection"
        >
        </ButtonComponent>
      </div>
    </div>
  </section>
</template>

<script>
import TitleComponent from "@/components/TitleComponent.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";

export default {
  name: "StartInvesting",
  components: {
    TitleComponent,
    ButtonComponent,
  },
  data() {
    return {
      bgImage: require("@/assets/img/section-7.jpg"),
    };
  },
  methods: {
    showSection() {
      this.$parent.showContactUs();
    },
  },
};
</script>

<style lang="scss" scoped></style>
