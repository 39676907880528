<template>
  <section
    class="contact-us container-fluid"
    :class="{ 'contact-us-active': showSection }"
  >
    <div class="row row-container">
      <div
        class="col-12 col-md-6 column-container form-container d-flex justify-content-center align-items-center py-5"
      >
        <button class="close-menu-btn btn" @click="hideSection">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32.626"
            height="19.428"
            viewBox="0 0 39.626 26.428"
          >
            <path
              id="Icon_ionic-ios-arrow-round-back"
              data-name="Icon ionic-ios-arrow-round-back"
              d="M22.238,11.758a1.8,1.8,0,0,1,.014,2.533L13.9,22.672H45.732a1.789,1.789,0,0,1,0,3.579H13.9l8.368,8.382a1.811,1.811,0,0,1-.014,2.533,1.782,1.782,0,0,1-2.519-.014L8.391,25.728h0a2.009,2.009,0,0,1-.372-.564,1.708,1.708,0,0,1-.138-.688,1.794,1.794,0,0,1,.509-1.252L19.733,11.8A1.753,1.753,0,0,1,22.238,11.758Z"
              transform="translate(-7.882 -11.252)"
              fill="#fff"
            />
          </svg>
        </button>
        <form class="form text-center w-50" @submit.prevent="sendContactData">
          <TitleComponent
            customClasses="title-two text-white mb-4 text-start pt-5 pt-md-0"
            text="Contáctanos"
          >
          </TitleComponent>
          <input
            type="text"
            class="input-form form-control mb-3 rounded-0"
            name="name"
            placeholder="Nombre"
            v-model="contact.name"
            required
          />
          <input
            type="number"
            class="input-form form-control mb-3 rounded-0"
            name="phone"
            placeholder="Teléfono"
            v-model="contact.phone"
            required
          />
          <input
            type="text"
            class="input-form form-control mb-5 rounded-0"
            name="email"
            placeholder="Correo"
            v-model="contact.email"
            required
          />
          <button
            type="submit"
            class="outline-custom-btn-2 btn px-5 py-2 mt-5 rounded-0"
          >
            Envía
          </button>
        </form>
      </div>
      <div
        class="col-12 col-md-6 column-container bg-image-container px-0"
        :style="{ backgroundImage: 'url(' + bgImage + ')' }"
      >
        <div class="content-container"></div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import TitleComponent from "@/components/TitleComponent.vue";
export default {
  name: "ContactUsPopUp",
  components: {
    TitleComponent,
  },
  props: {
    showSection: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      bgImage: require("@/assets/img/contact-us-image.jpg"),
      contact: {
        name: "",
        phone: "",
        email: "",
      },
      active: false,
    };
  },
  methods: {
    hideSection() {
      this.$parent.showContactUs();
      console.log("Hiding Section");
    },
    sendContactData() {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      //Adding form fields to an object
      let data = new FormData();
      data.append("name", this.contact.name);
      data.append("phone", this.contact.phone);
      data.append("email", this.contact.email);

      //sending data to via post
      axios
        .post("http://www.brownmillan.com/mailNotification.php", data, config)
        .then((res) => {
          console.log(res);
          if (res["status"] === 200) {
            this.$parent.showFormAlert();
          }
        })
        .catch((err) => console.log(err));

      this.contact.name = "";
      this.contact.phone = "";
      this.contact.email = "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
