<template>
  <button :class="customClasses" @click="executeMethod">
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "ButtonComponent",
  props: {
    customClasses: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: true,
    },
    method: {
      type: Function,
      required: false,
    },
  },
  methods: {
    executeMethod() {
      this.method();
      console.log("Method was executed");
    },
  },
};
</script>

<style lang="scss" scoped></style>
