<template lang="">
  <section class="how-does-the-fund-works container-fluid" id="howTheFundWorks">
    <div class="row row-container">
      <div class="col-12 col-md-6 col-xl-7 pt-5 column-container bg-gray-4">
        <div class="content-header ms-lg-5">
          <SubtitleComponent
            customClasses="text-white subtitle pt-5 pt-md-0 pt-xl-5 ms-xl-5 mb-3 pe-lg-5 ps-xl-0 wow slideInLeft"
            data-wow-duration="3s"
            text="¿Cómo funciona el fondo?"
          >
          </SubtitleComponent>
        </div>
        <div class="content ms-lg-5">
          <div
            class="line ms-xl-5 mb-3 mb-md-0 mb-xl-4 wow slideInLeft"
            data-wow-duration="3s"
          ></div>
          <p
            class="description ms-xl-5 pe-lg-5 ps-xl-0 wow slideInLeft"
            data-wow-duration="3s"
          >
            · Se hace una aportación de capital al fondo, nuestro equipo tiene
            proyectos listos para invertir en los que ya hicimos todos los
            análisis para saber que se cumplen con las métricas de un proyecto
            sustentable. <br />
            <br />
            · Estos proyectos pueden ser remodelaciones o simplemente comprar
            para renta directa o a través de Airbnb. <br />
            <br />
            · El fondo maneja todas las propiedades y busca un buen balance en
            el <span style="font-style: italic">cash-flow</span> con retornos a
            corto, mediano y largo plazo. <br />
            <br />
            · Los propietarios del fondo serán cada uno de los inversionistas en
            la proporción de su inversión, y el fondo, a su vez, será el
            propietario de los bienes inmobiliarios.
          </p>
        </div>
        <div class="content-footer ms-lg-5 pb-5 pb-md-0">
          <p class="description ms-xl-5 pb-5 pb-md-0 pe-lg-5 ps-xl-0">
            Brown & Millan Capital LLC será exclusivamente el administrador del
            fondo mas no el propietario de los bienes inmuebles.
          </p>
        </div>
      </div>
      <div
        class="col-12 col-md-6 col-xl-5 column-container bg-image"
        :style="{ backgroundImage: 'url(' + bgImage + ')' }"
      >
        <div
          class="blurred-image d-md-none wow slideInRight"
          data-wow-duration="3s"
        ></div>
      </div>
      <div
        class="blurred-image d-none d-md-block wow slideInRight"
        data-wow-duration="3s"
      ></div>
    </div>
  </section>
</template>
<script>
import SubtitleComponent from "@/components/SubtitleComponent.vue";

export default {
  name: "HowDoesTheFundWorks",
  components: {
    SubtitleComponent,
  },
  data() {
    return {
      bgImage: require("@/assets/img/fund-works-image.jpg"),
    };
  },
};
</script>
<style lang=""></style>
