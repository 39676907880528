<template lang="">
  <div
    class="menu-container"
    :class="active ? 'menu-opened' : 'menu-closed'"
    id="menuContainer"
  >
    <div class="menu">
      <ul class="nav-menu">
        <li class="menu-item mb-5">
          <span
            class="close-menu text-center text-md-end mb-3"
            id="closeMenu"
            @click="hideMenu"
          >
            <i class="far fa-times-circle"></i>
          </span>
        </li>
        <li class="menu-item px-3" v-for="link in menuItems" :key="link.id">
          <a :href="link.url" class="menu-link">
            {{ link.name }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "MenuComponent",
  data() {
    return {
      menuItems: [
        {
          id: 1,
          name: "¿Quienes somos?",
          url: "#aboutUs",
        },
        {
          id: 2,
          name: "Diversificación",
          url: "#diversification",
        },
        {
          id: 3,
          name: "¿Cómo funciona el fondo?",
          url: "#howTheFundWorks",
        },
        {
          id: 4,
          name: "Preguntas Frecuentes",
          url: "#frequentQuestions",
        },
      ],
      active: false,
    };
  },
  methods: {
    showMenu() {
      this.active = true;
    },
    hideMenu() {
      this.active = false;
    },
  },
};
</script>
<style lang=""></style>
