<template lang="">
  <section class="frequent-questions container-fluid" id="frequentQuestions">
    <div class="row row-container py-3 bg-black">
      <div class="col-12 column-container">
        <SubtitleComponent
          customClasses="text-white text-center subtitle"
          text="Preguntas frecuentes"
        >
        </SubtitleComponent>
      </div>
    </div>
    <div class="row row-container row-2">
      <div class="col-12 col-md-6 column-container px-0">
        <div
          class="frequent-question-card card-1"
          :class="{ 'card-activated': fqCardType1 }"
          @click="activateCardType1"
        >
          <div class="content">
            <h4 class="question">¿En qué estados invertimos?</h4>
            <p class="description">
              Actualmente invertimos en el estado de Texas, y esperamos
              expandirnos a Florida, Arizona y Colorado en los próximos años.
            </p>
          </div>
        </div>
        <div
          class="frequent-question-card card-2"
          :class="{ 'card-activated': fqCardType2 }"
          @click="activateCardType2"
        >
          <div class="content">
            <h4 class="question">
              ¿Dónde se pueden ver a detalle los proyectos de inversión?
            </h4>
            <p class="description">
              Somos fanáticos de la transparencia, por eso tenemos una
              plataforma en la cual se puede ver cada uno de los proyectos en
              los que está invirtiendo el fondo, su retorno actual y los
              detalles de cada proyecto como el costo de la propiedad, costo de
              operación, costo de remodelación, nuevo avalúo y más
              características. Cada inversionista tendrá su propio
              <span style="font-style: italic">login</span> y podrá ver a
              detalle todo lo relacionado con su inversión.
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 column-container px-0">
        <div
          class="frequent-question-card card-3"
          :class="{ 'card-activated-2': fqCardType3 }"
          @click="activateCardType3"
        >
          <div class="content">
            <h4 class="question">¿Qué riesgos hay?</h4>
            <p class="description">
              En todos los mercados existen riesgos, pero somos fieles creyentes
              de que los bienes raíces ofrecen el mejor balance entre las
              inversiones más seguras y el potencial de retorno más alto.
              <br />
              Incluso en el peor escenario, creemos que en un portafolio bien
              diversificado se minimizan esos riesgos todavía más.
            </p>
          </div>
        </div>
        <div
          class="frequent-question-card card-4"
          :class="{ 'card-activated-2': fqCardType4 }"
          @click="activateCardType4"
        >
          <div class="content">
            <h4 class="question">¿Qué garantías tenemos?</h4>
            <p class="description">
              Nuevamente reiteramos que los dueños siguen siendo los
              inversionistas, mientras nosotros somos solamente los
              administradores.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import SubtitleComponent from "@/components/SubtitleComponent.vue";

export default {
  name: "FrequentQuestions",
  components: {
    SubtitleComponent,
  },
  data() {
    return {
      fqCardType1: false,
      fqCardType2: false,
      fqCardType3: false,
      fqCardType4: false,
    };
  },
  methods: {
    activateCardType1() {
      this.fqCardType1 = !this.fqCardType1;
      this.fqCardType2 = false;
      this.fqCardType3 = false;
      this.fqCardType4 = false;
    },
    activateCardType2() {
      this.fqCardType2 = !this.fqCardType2;
      this.fqCardType1 = false;
      this.fqCardType3 = false;
      this.fqCardType4 = false;
    },
    activateCardType3() {
      this.fqCardType3 = !this.fqCardType3;
      this.fqCardType2 = false;
      this.fqCardType1 = false;
      this.fqCardType4 = false;
    },
    activateCardType4() {
      this.fqCardType4 = !this.fqCardType4;
      this.fqCardType1 = false;
      this.fqCardType2 = false;
      this.fqCardType3 = false;
    },
  },
};
</script>
<style lang=""></style>
