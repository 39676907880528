<template>
    <a :href="url" :class="customClasses">{{ text }}</a>
</template>

<script>
    export default {
        name: "LinkComponent",
        props: {
            url: {
                type: String,
                required: true,
            },
            customClasses: {
                type: String,
                required: true,
            },
            text: {
                type: String,
                required: true,
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>