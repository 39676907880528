<template>
  <footer class="bg-bamc-gray py-5">
    <div class="container-fluid footer-container py-5">
      <div
        class="row justify-content-center justify-content-md-between row-container"
      >
        <div
          class="col-12 col-sm-5 col-lg-4 my-auto text-center column-container"
        >
          <img
            :src="logo"
            alt=""
            class="img-fluid w-50 mb-5 mb-sm-0 wow slideInLeft"
            data-wow-duration="3s"
          />
        </div>
        <div class="col-12 col-sm-5 col-lg-4 my-auto column-container">
          <div
            class="contact-information text-center text-sm-start wow slideInRight"
            data-wow-duration="3s"
          >
            <h5 class="title mb-3">Contacto</h5>
            <p class="description mb-5">
              <b>Frank Brown</b><br />
              (832)305-3350 <br />
              Frank@brownmillan.com <br />
            </p>
            <a
              href="http://facebook.com"
              class="social-network-link me-4"
              target="_blank"
            >
              <i class="fab fa-facebook-f"></i>
            </a>
            <a
              href="http://instagram.com"
              class="social-network-link me-4"
              target="_blank"
            >
              <i class="fab fa-instagram"></i>
            </a>
            <a
              href="http://twitter.com"
              class="social-network-link"
              target="_blank"
            >
              <i class="fab fa-twitter"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      logo: require("@/assets/img/footer-logo.png"),
    };
  },
};
</script>

<style lang="scss" scoped></style>
