<template>
  <div
    class="form-alert"
    :class="{ 'active-alert': activateAlert }"
    id="formAlert"
  >
    <div class="form-alert-content">
      <span id="closeFormAlert" class="close-btn" @click="closeAlert">
        <i class="fas fa-times"></i>
      </span>
      <p class="message">¡Tu mensaje ha sido enviado con éxito!</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormAlert",
  data() {
    return {
      activateAlert: false,
    };
  },
  methods: {
    showAlert() {
      this.activateAlert = true;
    },
    closeAlert() {
      this.activateAlert = false;
    },
  },
};
</script>

<style scoped></style>
