<template>
  <div class="hero" :style="{ backgroundImage: 'url(' + bgImage + ')' }">
    <div class="content">
      <div
        class="logo-container d-flex justify-content-center align-items-center wow slideInDown"
        data-wow-duration="2s"
        data-wow-delay="0.5s"
      >
        <img :src="heroLogo" alt="" class="img-fluid wow slideInDown" />
      </div>
      <div class="menu-btn-container">
        <button class="menu-btn btn" id="menuBtn" @click="activateMenu">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35.291"
            height="21.86"
            viewBox="0 0 43.291 29.86"
          >
            <g
              id="Icon_feather-menu"
              data-name="Icon feather-menu"
              transform="translate(-3 -7.5)"
            >
              <path
                id="Trazado_46"
                data-name="Trazado 46"
                d="M4.5,18H44.791"
                transform="translate(0 4.43)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
              />
              <path
                id="Trazado_47"
                data-name="Trazado 47"
                d="M4.5,9H44.791"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
              />
              <path
                id="Trazado_48"
                data-name="Trazado 48"
                d="M4.5,27H44.791"
                transform="translate(0 8.86)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
              />
            </g>
          </svg>
        </button>
      </div>
      <div
        class="follow-us wow slideInLeft"
        data-wow-duration="2s"
        data-wow-delay="0.5s"
      >
        <div class="follow-us-content text-center">
          <p class="text-content text-white mb-5">Síguenos</p>
          <div class="line mx-auto"></div>
          <br />
          <a
            href="http://facebook.com"
            class="social-network-link"
            target="_blank"
          >
            <i class="fab fa-facebook-f"></i>
          </a>
          <br />
          <br />
          <a
            href="http://instagram.com"
            class="social-network-link"
            target="_blank"
          >
            <i class="fab fa-instagram"></i>
          </a>
          <br />
          <br />
          <a
            href="http://twitter.com"
            class="social-network-link"
            target="_blank"
          >
            <i class="fab fa-twitter"></i>
          </a>
        </div>
      </div>
      <div class="content-items text-center px-5 px-sm-0">
        <TitleComponent
          customClasses="text-white title-one text-center mb-5 wow slideInDown"
          data-wow-duration="2s"
          data-wow-delay="0.5s"
          text="Empieza a invertir"
        >
        </TitleComponent>
        <LinkComponent
          url="https://brownmillancapital.investnext.com/"
          customClasses="outline-custom-btn btn rounded-0 px-5 py-2 mb-3"
          text="LOGIN"
        >
        </LinkComponent>
        <br />
        <ButtonComponent
          customClasses="outline-custom-btn btn rounded-0 px-5 py-2"
          text="CONTÁCTANOS"
          :method="this.showSection"
        >
        </ButtonComponent>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent.vue";
import LinkComponent from "@/components/LinkComponent.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";

export default {
  name: "HeroComponent",
  components: {
    TitleComponent,
    LinkComponent,
    ButtonComponent,
  },
  data() {
    return {
      heroLogo: require("@/assets/img/hero-logo.png"),
      bgImage: require("@/assets/img/hero-background.jpg"),
    };
  },
  methods: {
    showSection() {
      this.$parent.showContactUs();
      //console.log("Button clicked");
    },
    activateMenu() {
      this.$parent.activateMenu();
    },
  },
};
</script>

<style lang="scss" scoped></style>
