<template>
  <HeroComponent></HeroComponent>
  <ContactUsPopUp :showSection="contactUs"></ContactUsPopUp>
  <AboutUs></AboutUs>
  <DiversificationSection></DiversificationSection>
  <HowDoesTheFundWorks></HowDoesTheFundWorks>
  <InvestmentPerformance></InvestmentPerformance>
  <FrequentQuestions></FrequentQuestions>
  <StartInvesting></StartInvesting>
  <MenuComponent ref="menu"></MenuComponent>
  <FormAlert ref="notification"></FormAlert>
  <FooterComponent></FooterComponent>
</template>

<script>
import "wowjs/css/libs/animate.css";
//import 'animate.css'
import { WOW } from "wowjs";

import HeroComponent from "@/sections/HeroComponent.vue";
import ContactUsPopUp from "@/sections/ContactUsPopUp.vue";
import AboutUs from "@/sections/AboutUs.vue";
import DiversificationSection from "@/sections/DiversificationSection.vue";
import HowDoesTheFundWorks from "@/sections/HowDoesTheFundWorks.vue";
import InvestmentPerformance from "@/sections/InvestmentPerformance.vue";
import FrequentQuestions from "@/sections/FrequentQuestions.vue";
import StartInvesting from "@/components/StartInvesting.vue";
import MenuComponent from "@/components/MenuComponent.vue";
import FormAlert from "@/components/FormAlert.vue";
import FooterComponent from "./components/FooterComponent.vue";
export default {
  name: "App",
  components: {
    HeroComponent,
    ContactUsPopUp,
    AboutUs,
    DiversificationSection,
    HowDoesTheFundWorks,
    InvestmentPerformance,
    FrequentQuestions,
    StartInvesting,
    MenuComponent,
    FormAlert,
    FooterComponent,
  },
  data() {
    return {
      contactUs: false,
      isMenuActive: false,
    };
  },
  mounted() {
    const wow = new WOW({
      boxClass: "wow", // animated element css class (default is wow)
      animateClass: "animated", // animation css class (default is animated)
      offset: 0, // distance to the element when triggering the animation (default is 0)
      mobile: true, // trigger animations on mobile devices (default is true)
      live: false, // act on asynchronously loaded content (default is true)
      scrollContainer: null, // optional scroll container selector, otherwise use window
    });
    wow.init();
  },
  methods: {
    showContactUs() {
      this.contactUs = !this.contactUs;
    },
    activateMenu() {
      this.$refs.menu.showMenu();
    },
    showFormAlert() {
      this.$refs.notification.showAlert();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/app.scss";
</style>
