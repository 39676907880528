<template lang="">
  <section class="about-us container-fluid pt-5 pt-md-0" id="aboutUs">
    <div
      class="row justify-content-center justify-content-md-start row-container align-items-center"
    >
      <div
        class="col-12 col-md-6 col-lg-4 col-xl-5 column-container ms-xl-5 mb-xl-5"
      >
        <div class="content ms-xl-5 mb-xl-5">
          <SubtitleComponent
            customClasses="custom-text-black subtitle wow slideInLeft"
            data-wow-duration="3s"
            text="¿Quiénes somos?"
          >
          </SubtitleComponent>
          <div class="line mb-3 wow slideInLeft" data-wow-duration="3s"></div>
          <p
            class="custom-text-black description wow slideInLeft pe-xl-5"
            data-wow-duration="3s"
          >
            Somos un fondo de inversión enfocado principalmente en bienes
            raíces.<br />
            Tenemos más de 20 años de experiencia en el ramo, desde construcción
            hasta compra venta y renta de bienes inmobiliarios en México y en
            Estados Unidos de América. Decidimos crear este vehículo de
            inversión para aprovechar las oportunidades de negocios en el
            mercado inmobiliario en EUA con el más alto crecimiento en los
            últimos 25 años.
          </p>
        </div>
      </div>
    </div>
    <div
      class="bg-image"
      :style="{ backgroundImage: 'url(' + bgImage + ')' }"
    ></div>
  </section>
</template>
<script>
import SubtitleComponent from "@/components/SubtitleComponent.vue";

export default {
  name: "AboutUs",
  components: {
    SubtitleComponent,
  },
  data() {
    return {
      bgImage: require("@/assets/img/about-us-image.jpg"),
    };
  },
};
</script>
<style lang=""></style>
