<template lang="">
  <section class="diversification container-fluid pt-5" id="diversification">
    <div class="row row-container justify-content-md-center">
      <div class="col-12 col-md-11 col-xl-10 column-container">
        <SubtitleComponent
          customClasses="text-white subtitle text-center mb-3 wow fadeInDown"
          text="Diversificación en tu inversión"
        >
        </SubtitleComponent>
        <div class="line mx-auto mb-3"></div>
        <p class="description text-center mb-4 wow fadeInDown">
          El portafolio ideal se diversifica en los siguientes mercados
        </p>
      </div>
      <div class="col-12 column-container d-md-none">
        <div
          id="carouselExampleControls"
          class="carousel slide mobile-carousel"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner carousel-container">
            <div
              class="carousel-item card-item-container"
              v-for="(item, index) in markets"
              :key="item.id"
              :class="{ active: index == 0 }"
            >
              <div
                class="card market-card"
                :style="{ backgroundImage: 'url(' + item.image + ')' }"
              >
                <div class="card-body market-card-body p-0">
                  <h6
                    v-html="item.title"
                    class="text-white text-center market-card-title"
                  ></h6>
                </div>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <div class="col-12 column-container px-0 d-none d-md-block">
        <span
          class="prev-btn card-container-buttons text-white d-none d-md-block"
          @click="executePrev"
        >
          <i class="fas fa-arrow-left"></i>
        </span>
        <span
          class="next-btn card-container-buttons text-white d-none d-md-block"
          @click="executeNext"
        >
          <i class="fas fa-arrow-right"></i>
        </span>
        <div class="card-container d-md-flex">
          <div
            class="card market-card"
            :class="{ 'card-activated': activateCarousel }"
            v-for="item in markets"
            :key="item.id"
            :style="{ backgroundImage: 'url(' + item.image + ')' }"
          >
            <div class="card-body market-card-body p-0">
              <h5
                v-html="item.title"
                class="text-white text-center market-card-title"
              ></h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import SubtitleComponent from "@/components/SubtitleComponent.vue";
export default {
  name: "DiversificationSection",
  components: {
    SubtitleComponent,
  },
  data() {
    return {
      markets: [
        {
          id: 1,
          title: "Bienes comerciales",
          image: require("@/assets/img/cards/card-1.jpg"),
        },
        {
          id: 2,
          title: "Bienes residenciales ",
          image: require("@/assets/img/cards/card-2.jpg"),
        },
        {
          id: 3,
          title: "Nuevas construcciones <br />residenciales",
          image: require("@/assets/img/cards/card-3.jpg"),
        },
        {
          id: 4,
          title:
            "<span style='font-style: italic;'>Flips</span> de <br />construcciones existentes",
          image: require("@/assets/img/cards/card-4.jpg"),
        },
        {
          id: 5,
          title: "<span style='font-style: italic;'>Flip</span> de tierra",
          image: require("@/assets/img/cards/card-5.jpg"),
        },
        {
          id: 6,
          title: "Multifamiliar",
          image: require("@/assets/img/cards/card-6.jpg"),
        },
      ],
      activateCarousel: false,
    };
  },
  methods: {
    executePrev() {
      if (window.innerWidth >= 768) {
        if (this.activateCarousel !== false) {
          this.activateCarousel = false;
        }
      }
    },
    executeNext() {
      if (window.innerWidth >= 768) {
        this.activateCarousel = true;
      }
    },
  },
};
</script>
<style lang=""></style>
