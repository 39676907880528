<template lang="">
  <h3 v-html="text" :class="customClasses"></h3>
</template>
<script>
export default {
  name: "SubtitleComponent",
  props: {
    text: {
      type: String,
      required: true,
    },
    customClasses: {
      type: String,
      required: false,
    },
  },
};
</script>
<style lang=""></style>
