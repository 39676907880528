<template>
  <h2 v-html="text" :class="customClasses"></h2>
</template>

<script>
export default {
  name: "TitleComponent",
  props: {
    text: {
      type: String,
      required: true,
    },
    customClasses: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
